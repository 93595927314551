Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

const PASSWORD_REQ_MESSAGE = "Password req:1 uppercase & 1 lowercase letter,1 digit,1 special character & min 8 characters";

exports.onlineCoursesText="Login to access and learn fromour 300+ online courses..";
exports.email_regex=/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
exports.emailErrorMessage="Please enter a valid email address.";
exports.password_regex=/^(?=.*[A-Z])(?=.*[!@#$%&*])(?=.*\d)(?=.*[a-z]).{8,}$/;
exports.passwordErrorMessage=PASSWORD_REQ_MESSAGE;
exports.reTypePasswordErrorMessage="The passwords do not match. Please make sure you enter the same password in both fields";
exports.phoneNumberErrorMessage="Please enter a phone number.";
exports.phone_regex=/^[0-9\b]+$/;
exports.site_info="© 2022 Jumbo  Terms  Privacy • • • ";
exports.signUpTitleText="Sign up on Jumbo Career";
exports.signUpSubtitleText=" Create your account by filling the details below.";
exports.proceedAgreetText="By proceeding, you agree to our";
exports.termsServiceText="Terms of service";
exports.privacyPolicyText = "Privacy Policy";
exports.andText="and ";
exports.buttonText="Let’s go";
exports.signUpWithText="Or signup with";
exports.alreadyAccountText="Already have an account?";
exports.loginText=" Login";
exports.slectUsertype="Select User type";
exports.slectusertypeStudent="Student";
exports.slectusertypeInstructor="Instructor";
exports.slectusertypeRecruiter="Recruiter";
exports.siteInfo="© 2022 Jumbo  Terms  Privacy • • • "
exports.clientIdCode = "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";

exports.jumbo_career="jumbo_career.png";
exports.alert_icon="alert_icon.svg";
exports.camera_icon="camera_icon.svg";
exports.file_icon="file_icon.svg";
exports.search_icon="search_icon.svg";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

//add job///

exports.addJobApiMethodPost="POST";

//put job

exports.putJobApiMethodPost="PUT";

//delet job

exports.deleteJobApiMethodPost="DELETE";

//job list
exports.joblistEndPoint="bx_block_content_management/job_vacancies/search_by_job_status"
exports.jobEndPoint="bx_block_content_management/job_vacancies"
exports.userScheduleEndPoint = "bx_block_calendar/user_schedules"
exports.exampleAPiMethod = "POST"


exports.getAllCoursesEndPoint = 'bx_block_content_management/course_details';
exports.FavouriteCoursesEndPoint = 'bx_block_content_management/course_details/favourite_course';
exports.landingPageEndPoint = 'bx_block_landingpage2/landing_pages';

exports.getCourseDetailEndPoint = 'bx_block_content_management/course_details';
exports.addCourseSkillEndPoint = 'bx_block_content_management/course_details/add_skills';
exports.updateCourseSkillEndPoint = 'bx_block_content_management/course_details/update_skills';
exports.deleteCourseSkillEndPoint = 'bx_block_content_management/course_details/delete_skills';
exports.addCourseModuleEndPoint = 'bx_block_content_management/course_details/add_course_module';
exports.deleteModuleEndPoint = 'bx_block_content_management/course_details/delete_course_module';
exports.inactiveCourseEndPoint = 'bx_block_content_management/course_details/inactive_course';
exports.courseByPopularCategoryEndPoint = 'bx_block_content_management/course_details/popular_categories_courses';
exports.getProfileInfoEndPoint = 'account_block/user_profiles/get_profile_info';
exports.uploadCertificateEndPoint = 'account_block/user_profiles/upload_certificate';
exports.updateProfileInfoEndPoint = 'account_block/user_profiles/profile_info';
exports.overallCourseRatingEndPoint = 'bx_block_content_management/course_details/overall_course_rating';
// Customizable Area End
