// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";
import * as yup from "yup";
import * as H from 'history'
import React, {createRef} from "react";
import moment from 'moment'
import {toast} from 'react-toastify'

export const configJSON = require("./config");

export interface Match {
    // Customizable Area Start
    params: {
        id?: number;
    };
    isExact: boolean;
    path: string;
    url: string;
    // Customizable Area End
}
export interface Props {
    navigation: any;
    id: string;
    history: H.History;
    location: H.Location;
    updateCC: any;
    match: Match;
}

type Option = {
    name: string;
    value: string | number
}

type Skill = {
    id?: string | number,
    skill_name: string
}

export interface CourseForm {
    name: string;
    description: string;
    course_image?: any;
    category_id: number | string;
    course_sub_category_id: number | string;
    choice: number | string;
    level: number | string;
    currency_id: number | string;
    price: number | string;
    course_overview: string;
    start_date: string;
    end_date: string;
    recurring_meeting: boolean;
    recurrence: string;
    repeat_every: string|number;
    occurs_on: string | number;
    skill: Skill[]
}

interface updateFormPayload {
    course_sub_category_id: string;
    start_date: string;
    end_date: string;
    recurring_meeting: boolean;
    recurrence: string;
    repeat_every: string | number;
    occurs_on: string | number;
}


type CourseFormKeys = keyof CourseForm;

interface ErrorObj {
    visited: boolean;
    errorMessage: string;
}

interface S {
    token: string;
    loading: boolean;
    prevCourseForm?: CourseForm;
    courseForm: CourseForm;
    errors: Array<any>;
    courseFormErrors: Record<CourseFormKeys, ErrorObj>;
    categoryOptions: Option[];
    subCategoryOptions: Option[];
    courseTypeOptions: Option[];
    courseLevelOptions: Option[];
    currencyOptions: Option[];
    dayNumberOptions: Array<number>;
    daysOptions: Array<string>;
    isSaveButtonDisabled : boolean;
}

interface SS {
    id: any;
}

export default class EditCourseController extends BlockComponent<Props, S, SS> {
    courseFormValidationFields: CourseFormKeys[] = [
        "name",
        "description",
        "course_image",
        "category_id",
        "course_sub_category_id",
        "choice",
        "level",
        "currency_id",
        "price",
        "course_overview",
        "start_date",
        "end_date",
        "recurring_meeting",
        "recurrence",
        "repeat_every",
        "occurs_on",
        "skill"
    ];
    courseFormValidation: any = yup.object().shape({
        name : yup.string().trim().required('Name is required field').min(3, 'Name must be greater then 3 character').max(60, 'Name must be less then 60 character'),
        description : yup.string(),
        course_image: yup.object(),
        category_id : yup.string().required(),
        choice: yup.string().required(),
        level : yup.string().required(),
        currency_id : yup.string().required(),
        price : yup.string().required('Price is required field').matches(/^\d+(\.\d+)?$/, 'Price accept only number and decimal value'),
        course_overview : yup.string(),
        start_date : yup.string(),
        end_date : yup.string(),
        skill : yup.array().of(yup.object().shape({
            skill_name : yup.string().trim().required('skill name is required')
        }))
    });
    getCourseDetailsAPICallID?: string = '';
    createCourseDetailsAPICallID?: string = '';
    updateCourseDetailsAPICallID?: string = '';
    deleteSkillAPICallID?: string = '';
    getProductApiCallId?: any;
    getOptionListID: string = '';
    imageUrl = '';
    fileInputRef: React.RefObject<HTMLInputElement>
    module = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean'],
            ['undo', 'redo']
        ],
    }
    format = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    constructor(props: Props) {
        super(props);
        this.fileInputRef = createRef()
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        const courseFormErrors: Record<CourseFormKeys, ErrorObj> = this.courseFormValidationFields.reduce((
            acc: Record<CourseFormKeys, ErrorObj>, key: CourseFormKeys
        ) => ({
            ...acc,
            [key]: {
                visited: false,
                errorMessage: ""
            }
        }), {} as Record<CourseFormKeys, ErrorObj>);

        this.state = {
            token: "",
            loading: true,
            courseForm: {
                name: "",
                description: "",
                course_image: null,
                category_id: "",
                course_sub_category_id: "",
                choice: "",
                level: "",
                currency_id: "",
                price: "",
                course_overview: "",
                start_date: "",
                end_date: "",
                recurring_meeting:false,
                recurrence:"",
                repeat_every:"",
                occurs_on: "",
                skill: [
                    {
                        skill_name: ""
                    }
                ]
            },
            errors: [],
            courseFormErrors,
            categoryOptions: [],
            subCategoryOptions: [],
            courseLevelOptions: [],
            courseTypeOptions: [],
            currencyOptions: [],
            dayNumberOptions:[1,5,10,15,20,25,30],
            daysOptions: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            isSaveButtonDisabled : true
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        this.validateCourseForm()
        if (!this.isPlatformWeb()) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
        const token = (typeof localStorage !== "undefined" && localStorage.getItem('token')) || '';
        this.setState({ token: token });
        await this.getOptionList(token);
        await this.getCourseDetails(token);
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    async receive(from: string, message: Message) {

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (message.id !== getName(MessageEnum.RestAPIResponceMessage)) {
            return;
        }

        switch (apiRequestCallId) {
                case this.getOptionListID :
                    if (responseJson) {
                        if (!responseJson.errors) {
                            this.setState({
                                loading: false,
                                categoryOptions: (responseJson.all_categories || []).map(({title, id}: {
                                    title: string,
                                    id: number
                                }) => ({name: title, value: id})),
                                subCategoryOptions: (responseJson.all_sub_categories || []).map(({name, id}: {
                                    name: string,
                                    id: number
                                }) => ({name: name, value: id})),
                                courseTypeOptions: Object.keys(responseJson.all_course_type || {}).map((key: string) => ({
                                    name: key,
                                    value: responseJson.all_course_type[key]
                                } as Option)),
                                courseLevelOptions: Object.keys(responseJson.all_levels || {}).map((key: string) => ({
                                    name: key,
                                    value: responseJson.all_levels[key]
                                })),
                                currencyOptions: (responseJson.all_currencies || []).map(({name, id}: {
                                    name: string,
                                    id: number
                                }) => ({
                                    name,
                                    value: id
                                }))
                            });
                        }
                    }

                    break;
                case this.getCourseDetailsAPICallID :
                    if (responseJson) {
                        if (responseJson.data) {
                            const courseInfo = responseJson.data.attributes;
                            this.imageUrl = courseInfo.course_image
                            const obj = {
                                name: courseInfo.name,
                                description: courseInfo.description,
                                category_id: courseInfo.category_id ?? "",
                                course_sub_category_id: courseInfo.sub_category_id ?? "",
                                choice: courseInfo.course_type_id ?? "",
                                level: courseInfo.level_id ?? "",
                                currency_id: courseInfo.currency_id,
                                price: courseInfo.price,
                                course_overview: courseInfo.course_overview,
                                end_date: moment(courseInfo.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                start_date: moment(courseInfo.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                recurring_meeting: courseInfo.recurring_meeting,
                                recurrence: courseInfo.recurrence,
                                repeat_every: courseInfo.repeat_every,
                                occurs_on: courseInfo.occurs_on,
                                skill: (courseInfo.skill_info.data || []).map(({id, attributes: {skill_name}}: {
                                    id: string | number,
                                    attributes: {
                                        skill_name: string
                                    }
                                }) => ({
                                    id,
                                    skill_name
                                }))
                            }
                            this.setState({
                                courseForm: {
                                    ...this.state.courseForm,
                                    ...obj
                                },
                                prevCourseForm: {
                                    ...obj
                                }
                            })
                            this.validateCourseForm(obj)
                        }
                    }
                    break;
                case this.createCourseDetailsAPICallID :
                    if (responseJson) {
                        if (responseJson.data) {

                            this.setState({
                                ...this.state,
                                errors: [],
                            })
                            await this.showToast('Course Created Successfully')
                            await this.redirectToCourse()

                        } else if (responseJson.errors) {
                            this.setState({
                                ...this.state,
                                courseForm: {...this.state.courseForm},
                                errors: responseJson.errors
                            })
                        }
                    }
                    break;
                case this.updateCourseDetailsAPICallID :
                    if (responseJson) {
                        if (responseJson.data) {
                            this.setState({
                                ...this.state,
                                errors: [],
                            })
                            await this.showToast('Course Updated Successfully')
                            await this.redirectToCourse()
                        }
                    }
                    break;
                case this.deleteSkillAPICallID :
                    await this.showToast('Skill Deleted Successfully')
                    await this.getCourseDetails(this.state.token)
                    break;
            }

    }

    async redirectToCourse() {
        return await new Promise(resolve => setTimeout(() => {
            this.props.history.push('/instructor/course')
        }, 3000));
    }
    async showToast(message: string) {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    async getOptionList(token?: string) {
        const headers = {
            token: this.state.token || token
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getOptionListID = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllCoursesEndPoint + '/info_listing'
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    async  getCourseDetails(token?: string) {
        if (this.props.match.params.id) {

            const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getCourseDetailsAPICallID = getValidationsMsg.messageId;

            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getCourseDetailEndPoint}/${this.props.match.params.id}`
            );

            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify({
                    token: this.state.token || token
                })
            );

            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
        }
    }
    /** Methods */
    validateCourseForm = (values : CourseForm = this.state.courseForm) => {
        this.courseFormValidation.validate(values, {abortEarly: false}).then((result : any) => {
            this.setState({
                courseFormErrors : this.courseFormValidationFields.reduce((acc : Record<CourseFormKeys, ErrorObj>, key : CourseFormKeys) => ({
                    ...acc,
                    [key] : {
                        visited : this.state.courseFormErrors[key]?.visited,
                        errorMessage : ""
                    }
                }), {} as Record<CourseFormKeys, ErrorObj>),
                isSaveButtonDisabled : false
            })

        }).catch((error : any) => {
            const errors = error.inner.reduce((acc : Record<CourseFormKeys, ErrorObj>, {path, message} : {path : string, message : string}) => ({
                ...acc,
                [path.includes('skill') ? 'skill' : path as CourseFormKeys] : {
                    visited : this.state.courseFormErrors[path as CourseFormKeys]?.visited,
                    errorMessage : message
                }
            }), {} as Record<CourseFormKeys, ErrorObj>);

            this.setState({
                courseFormErrors : {
                    ...this.state.courseFormErrors,
                    ...this.courseFormValidationFields.reduce((acc : Record<CourseFormKeys, ErrorObj>, key : CourseFormKeys) => ({
                        ...acc,
                        [key] : {
                            visited : this.state.courseFormErrors[key]?.visited,
                            errorMessage : ""
                        }
                    }), {} as Record<CourseFormKeys, ErrorObj>),
                    ...errors
                },
                isSaveButtonDisabled : true
            })
        })
    };

    /** Handlers */
    onCourseImageButtonClickHandler = () => {
        if (!this.imageUrl)
            this.fileInputRef?.current?.click?.()
    };

    onCourseImageCloseIconClickHandler = (event: any) => {
        event.stopPropagation();
        this.imageUrl = ''
        this.setState({
            courseForm: {
                ...this.state.courseForm,
                course_image: ""
            }
        });
    };
    onDeleteSkillButtonClickHandler = async (index: number, id?: string | number) => {
        if (id) {
            await this.deleteSkill(id, this.state.token)
            this.setState({
                courseForm: {
                    ...this.state.courseForm,
                    skill: this.state.courseForm.skill.filter((_, i) => i !== index)
                }
            })
        } else {
            this.setState({
                courseForm: {
                    ...this.state.courseForm,
                    skill: this.state.courseForm.skill.filter((_, i) => i !== index)
                }
            })
        }
    };

    onAddSkillButtonClickHandler = () => {
        this.setState({
            courseForm: {
                ...this.state.courseForm,
                skill: [...this.state.courseForm.skill, {skill_name: ""}]
            }
        })
    };

    onBlur = (event: any) => {
        const name : CourseFormKeys = event.target.name.includes('skill') ? 'skill' : event.target.name;
        this.setState({
            courseFormErrors: {
                ...this.state.courseFormErrors,
                [name]: {
                    visited: true,
                    errorMessage: this.state.courseFormErrors[name]?.errorMessage
                }
            }
        });
    };

    onChange = (event: any) => {
        console.log('event', event.target.name, event.target.value)
        let value:any;
        const name = event.target.name;
        if(event.target.type === "file"){
            if(event.target.files?.[0]){
                this.imageUrl = URL.createObjectURL(event.target.files?.[0]);
                value = event.target.files?.[0];
            }
            else{
                value = '';
            }
        }
        else{
            value = event.target.value;
        }


        if(name.includes("skill-")){
            const skillValue: any = this.state.courseForm.skill.map(({id, skill_name}, index) => ({
                id: id,
                skill_name : +name.replace("skill-", "") === index ? value : skill_name
            }))
            this.setState(()=>({
                courseForm: {
                    ...this.state.courseForm,
                    skill: skillValue
                }
            }));
            this.validateCourseForm({...this.state.courseForm, skill: skillValue})
        }else if(name==='recurring_meeting'){
            value = event.target.checked
            if(value){
                this.setState(()=>({
                    courseForm: {
                        ...this.state.courseForm,
                        [name]: value,
                        recurrence: "daily",
                        repeat_every: 1,
                        occurs_on: "",
                    }
                }));
                this.validateCourseForm({
                    ...this.state.courseForm,
                    [name]: value,
                    recurrence: "daily",
                    repeat_every: 1,
                    occurs_on: "",
                })
            }
            else{
                this.setState(()=>({
                    courseForm: {
                        ...this.state.courseForm,
                        [name]: value,
                        recurrence: "",
                        repeat_every: "",
                        occurs_on: "",
                    }
                }));
                this.validateCourseForm({
                    ...this.state.courseForm,
                    [name]: value,
                    recurrence: "",
                    repeat_every: "",
                    occurs_on: "",
                })
            }
        }
        else {
            console.log('set state', event.target.name, event.target.value)
            this.setState(()=>({
                courseForm: {
                    ...this.state.courseForm,
                    [name]: value
                }
            }));
            this.validateCourseForm({...this.state.courseForm, [event.target.name]: value})
        }
    }

    onCancelButtonClickHandler = () => {
        this.props.history.push('/instructor/course');
    }

    async deleteSkill(skillId?: string | number, token?: string) {
        const deleteSkill = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteSkillAPICallID = deleteSkill.messageId;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token || token
        };
        deleteSkill.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteCourseSkillEndPoint}?skill_id=${skillId}`
        );

        deleteSkill.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        deleteSkill.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteJobApiMethodPost
        );
        runEngine.sendMessage(deleteSkill.id, deleteSkill);
    }

    async createCourseDetails(token?: string, payload?: any) {
        const createFromDetails = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createCourseDetailsAPICallID = createFromDetails.messageId;
        const header = {
            token: this.state.token || token
        };
        createFromDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCourseDetailEndPoint
        );

        createFromDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        createFromDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        createFromDetails.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            payload
        );

        runEngine.sendMessage(createFromDetails.id, createFromDetails);
    }

    async updateCourseDetails(token?: string, payload?: any) {
            const updateFromDetails = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateCourseDetailsAPICallID = updateFromDetails.messageId;
            const header = {
                token: this.state.token || token
            };
            updateFromDetails.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getCourseDetailEndPoint}/${this.props.match.params.id}`
            );

            updateFromDetails.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            updateFromDetails.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putJobApiMethodPost
            );

            updateFromDetails.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                payload
            );

            runEngine.sendMessage(updateFromDetails.id, updateFromDetails);
    }

    onSaveButtonClickHandler = async () => {
        let courseUpdateFormObject = this.courseFormValidationFields.filter(e => e !== "skill").reduce((acc: any, key: CourseFormKeys) => (
            {
            ...acc,
            ...(this.state.courseForm[key] !== this.state.prevCourseForm?.[key] ? {[key] : this.state.courseForm[key]} : {})
        }), {} as any);
        courseUpdateFormObject = {
            ...courseUpdateFormObject,
            start_date: courseUpdateFormObject.start_date && moment(courseUpdateFormObject.start_date).format('DD/MM/YYYY'),
            end_date: courseUpdateFormObject.end_date && moment(courseUpdateFormObject.end_date).format('DD/MM/YYYY')
        }
        const formData = new FormData()
        Object.keys(courseUpdateFormObject).forEach(function (key, index) {
            if (courseUpdateFormObject[key]) {
                formData.append(key.toString(), courseUpdateFormObject[key])
            }
        });
        const courseSkills = this.state.courseForm.skill.filter((skill: Skill) => skill.skill_name.length > 0)
        if (courseSkills.length > 0) {
            for (let i = 0; i < courseSkills.length; i++) {
                const skill = courseSkills[i]
                if (skill.id) {
                    formData.append(`skills_attributes[${i}][id]`, skill.id.toString())
                }
                formData.append(`skills_attributes[${i}][skill_name]`, skill.skill_name)
            }
        }
        if (this.props.match.params.id) {
            await this.updateCourseDetails(this.state.token, formData);
        } else {
            await this.createCourseDetails(this.state.token, formData);
        }
    }
}
// Customizable Area End