export const imgPasswordVisible = require("./assets/ic_password_visible.png");
export const imgPasswordInVisible = require("./assets/ic_password_invisible.png");
export const dasboardMenuIcon = require("./assets/dasboard-menu-icon.png");
export const courseMenuIcon = require("./assets/course-menu-icon.png");
export const studentMenuIcon = require("./assets/student-menu-icon.png");
export const accountMenuIcon = require("./assets/account-menu-icon.png");
export const calendarMenuIcon = require("./assets/calendar-menu-icon.png");
export const helpMenuIcon = require("./assets/help-menu-icon.png");
export const assessmentMenuIcon = require("./assets/assessment-menu-icon.png");
export const assessmentNewIcon = require("./assets/assessment-new-icon.png");
export const notificationHeader = require("./assets/notification-bell.png")
export const downHeader = require("./assets/down-header.png")
export const forwardIcon = require("./assets/forward-icon.png")
export const downArrowIcon = require("./assets/down-arrow-icon.png")
export const upDownArrow = require("./assets/up-down-arrow.png")
export const menuIcon = require("./assets/menu-icon.png")
export const eyeIcon = require("./assets/eye-icon.png")
export const jumboLogo = require("./assets/jumbocareer.png")
export const jumboText = require("./assets/jumbocarreetext.png")
export const profileIcon = require("./assets/profile-icon.png")
export const correctSelectedIcon = require("./assets/correct-selected.png")
export const correctUnselectedIcon = require("./assets/correct-unselected.png")
export const wrongSelectedIcon = require("./assets/wrong-selected.png")
export const wrongUnselectedIcon = require("./assets/wrong-unselected.png")
export const courseIcon = require("./assets/course-icon.png")
export const dotIcon = require("./assets/dot-icon.png")
export const circleplus = require('./assets/add-circle-icon.png')
export const  activecourseIcon  = require ('./assets/active-course-icon.png')
export const  assessmentcreateIcon = require ( './assets/assessment-created-icon.png')
export const  questionCreatedIcon = require ( './assets/question-created-icon.png');
export const  studentEngagedIcon = require ( './assets/student-engaged-icon.png');
export const  totalMoneyEarned = require ( './assets/total-money-earned-icon.png');
export const  greenUpIcon = require ( './assets/green-up-icon.png');
export const  redDownIcon = require ( './assets/red-down-icon.png')
export const  greyAssessmentIcon = require ( './assets/assessment-grey-icon.png')
export const  greyMonthlyIcon = require ( './assets/monthly-cal-icon.png')
export const greyDownArrow = require ( './assets/greyDownArrow.png')
export const whiteDownArrow = require ( './assets/whiteDownArrow.png')
export const leftArrowIcon = require("./assets/left-arrow.png")
export const rightArrowIcon = require("./assets/right-arrow.png")
export const faceHeaderIcon = require("./assets/face-header.png")
export const analyticeSideBarIcon = require("./assets/analyticeSideBarIcon.png");
export const blankBlueIcon = require("./assets/blankBlueIcon.png")
export const deleteIcon = require("./assets/deleteIcon.png")
export const editPenIcon = require("./assets/editPenIcon.png")
export const greyEyeIcon = require("./assets/greyEyeIcon.png")
export const errorIconBackground = require("./assets/error-code-background.png");
export const PREIVARROW_S = require("./assets/single_prev_arrow.png");
export const NEXTARROW_S = require("./assets/single_next_arrow.png");
export const PREIVARROW_D = require("./assets/double_prev_arrow.png");
export const NEXTARROW_D = require("./assets/double_next_arrow.png"); 
export const EXPEND_ARROW = require("./assets/expend_arrow.png");
export const LIKE = require("./assets/like.png");
export const DISLIKE = require("./assets/dislike.png");
export const CANCEL = require("./assets/cancel.png");
export const BELLICON = require('./assets/bell_icon.png');
export const LOGO = require('./assets/logo.png');
export const DROPARROW = require('./assets/drop_icon.png');
export const PROFILE = require('./assets/profile.png');
export const EDIT = require('./assets/edit.png');
export const OVEL = require('./assets/oval.png');
export const PLAY = require('./assets/play.png');
export const SUPPORT = require('./assets/support.png');
export const SETTING = require('./assets/setting.png');
export const STUDENT = require('./assets/student_icon.png');
export const COURSE = require('./assets/course_icon.png');
export const DASHBOARD = require('./assets/dashboard.png');
export const CALANDER = require('./assets/calander.png');
export const ASSIGNMENT = require('./assets/assignment.png');
export const LINE = require('./assets/line.png');
export const BG = require('./assets/bg.png');
export const RIGHTARROW = require('./assets/right_arrow.png');
export const MORE = require('./assets/more.png');
export const LIST = require('./assets/list.png');
export const CARD = require('./assets/card.png');
export const SEARCH = require('./assets/search.png');
export const WHITEPLUS = require('./assets/white_plus.png');
export const BLUEPLUS = require('./assets/blue_plus.png');
export const DELETE = require('./assets/delete.png');
export const ACTIVE = require('./assets/active.png');
export const IMAGEURL = require('./assets/imageurl.png');
export const CLOSE = require('./assets/close.svg');
export const LIGHTUPARROW = require('./assets/light_up.png');
export const UPARROW = require('./assets/up.png');
export const LIGHTDOWNARROW = require('./assets/light_down.png');
export const DOWN = require('./assets/down.png');
export const PLAYVIDEOO = require('./assets/play_video.png');
export const READ = require('./assets/read_icon.png');
export const PLAYFILL = require('./assets/play_fill.png');
export const MIC = require('./assets/mic.png');
export const VIDETHMNAIL = require('./assets/video_th.png');
export const BACK = require('./assets/back_icon.png');
export const FILE = require('./assets/file.png');
export const CAMERA = require('./assets/camera.png');
export const BLUEMIC = require('./assets/blue_mic.png');
export const EMOJI = require('./assets/emoji.png');
export const PDFICON = require('./assets/pdf.png');
export const RIGHTOUTLINEARROW = require('./assets/right_arrow_outline.png');
export const RADIOSET = require('./assets/radio_set.png');
export const RADIOSUNET = require('./assets/radio_unset.png');
