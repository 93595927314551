import React from "react";
// Customizable Area Start
import {Box, ButtonBase, Checkbox, FormControl, MenuItem, Paper, Select, Typography,} from "@material-ui/core";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import './EditCourse.css'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Input, Radio} from 'antd';
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

// Customizable Area End
import EditCourseController, {CourseForm, Props} from "./EditCourseController";
import {BLUEPLUS, CLOSE, DELETE, RIGHTARROW} from "../../../framework/src/Themes/ImageConstants";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: ["Roboto", "Helvetica Neue", "Arial", "sans-serif"].join(","),
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    }
});

export default class EditCourseWeb extends EditCourseController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    renderLabelClasses = (fieldName: keyof CourseForm) => {
        if (this.state.courseFormErrors[fieldName]) {
            if (
                this.state.courseFormErrors[fieldName].visited &&
                this.state.courseFormErrors[fieldName].errorMessage
            ) {
                return "erroredLabel";
            } else {
                return "";
            }
        }
    };
    renderErroredInputClasses = (fieldName: keyof CourseForm) => {
        if (this.state.courseFormErrors[fieldName]) {
            if (
                this.state.courseFormErrors[fieldName].visited &&
                this.state.courseFormErrors[fieldName].errorMessage
            ) {
                return "erroredInput";
            } else {
                return "";
            }
        }
    };
    renderCourseImage = (courseImage?: string) => {
        if (courseImage) {
            return (
                <>
                    <img
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        src={courseImage}
                        alt="selected image"
                        data-test-id="course_image"
                    />
                    <Box
                        data-test-id="remove_image_icon"
                        sx={webStyle.fileRemoveBtn}
                        onClick={this.onCourseImageCloseIconClickHandler}
                    >
                        <img alt={"close"} src={CLOSE}/>
                    </Box>
                </>
            );
        } else {
            return (
                <>
                    <Typography
                        style={webStyle.fileUploadBoxText}
                        align={"center"}
                        variant="body2"
                    >
                        Upload file from <br/> local
                    </Typography>
                    <p style={{color: "lightgrey"}}>Or</p>
                    <Typography
                        style={webStyle.fileUploadBoxText}
                        align={"center"}
                        variant="body2"
                    >
                        Select file from <br/> Server
                    </Typography>
                </>
            );
        }
    }
    renderOccursOn = () => {
        if (this.state.courseForm.recurrence === 'weekly') {
            return (
                <>
                    <label className={`editCourseFormLabel`} style={{flex: '0 0 10em'}}>Occurs On</label>
                    <FormControl fullWidth style={{display: 'flex', flexDirection: 'row'}}>
                        {
                            this.state.daysOptions.map((value) => (
                                <label htmlFor="" key={value} style={{width: '100px'}}>
                                    <Checkbox
                                        data-test-id="course_days_options"
                                        color="primary"
                                        name="occurs_on"
                                        value={value}
                                        checked={this.state.courseForm.occurs_on === value}
                                        onChange={(event: any) => this.onChange(event)}
                                    /> {value}
                                </label>
                            ))
                        }

                    </FormControl>
                </>
            )
        }
        if (this.state.courseForm.recurrence === 'monthly') {
            return (
                <>
                    <label className={`editCourseFormLabel`} style={{flex: '0 0 10em'}}>Occurs On</label>
                    Day <Select variant="outlined"
                                name="occurs_on"
                                className={`${this.renderErroredInputClasses('occurs_on')}`}
                                value={this.state.courseForm.occurs_on}
                                displayEmpty
                                renderValue={this.state.courseForm.occurs_on !== "" ? undefined : () => "Select"}
                                onBlur={this.onBlur}
                                onClose={() => this.onBlur({
                                    target: {
                                        name: "occurs_on",
                                        value: this.state.courseForm.occurs_on
                                    }
                                })}
                                onChange={this.onChange}
                                style={{...webStyle.selectInput, width: '100px'}}
                                data-test-id="course_occurs_on_input"
                >

                    {
                        this.state.dayNumberOptions.map(value => <MenuItem
                            value={value} key={value}>
                            <Typography style={webStyle.selectMenuItemText}>
                                {value}
                            </Typography>
                        </MenuItem>)
                    }
                </Select> Of the month
                </>
            )
        }
    }
    renderRecurringMeetingElements = (recurringMeeting: boolean) => {
        let repeatLabel: string;
        if (this.state.courseForm.recurrence === 'daily') {
            repeatLabel = 'day(s)'
        } else if (this.state.courseForm.recurrence === 'weekly') {
            repeatLabel = 'Week(s)'
        } else {
            repeatLabel = 'month(s)'
        }

        if(recurringMeeting){
            return (
                <>
                    <Box sx={webStyle.priceContainer}>
                        <label className={`editCourseFormLabel`} style={{flex: '0 0 10em'}}>Recurrence</label>
                        <Select variant="outlined"
                                name="recurrence"
                                className={`${this.renderErroredInputClasses('recurrence')}`}
                                value={this.state.courseForm.recurrence}
                                displayEmpty
                                renderValue={this.state.courseForm.recurrence !== "" ? undefined : () => "Select"}
                                onBlur={this.onBlur}
                                onClose={() => this.onBlur({
                                    target: {
                                        name: "recurrence",
                                        value: this.state.courseForm.recurrence
                                    }
                                })}
                                onChange={this.onChange}
                                style={{...webStyle.selectInput, width: '250px'}}
                                data-test-id="course_recurrence_input"
                        >
                            <MenuItem value="daily">
                                <Typography style={webStyle.selectMenuItemText}>
                                    Daily
                                </Typography>
                            </MenuItem>
                            <MenuItem value="weekly">
                                <Typography style={webStyle.selectMenuItemText}>
                                    Weekly
                                </Typography>
                            </MenuItem>
                            <MenuItem value="monthly">
                                <Typography style={webStyle.selectMenuItemText}>
                                    Monthly
                                </Typography>
                            </MenuItem>

                        </Select>
                    </Box>
                    <Box sx={webStyle.priceContainer}>
                        <label className={`editCourseFormLabel`} style={{flex: '0 0 10em'}}>Repeat Every</label>
                        <Select variant="outlined"
                                name="repeat_every"
                                className={`${this.renderErroredInputClasses('repeat_every')}`}
                                value={this.state.courseForm.repeat_every}
                                displayEmpty
                                renderValue={this.state.courseForm.repeat_every !== "" ? undefined : () => "Select"}
                                onBlur={this.onBlur}
                                onClose={() => this.onBlur({
                                    target: {
                                        name: "repeat_every",
                                        value: this.state.courseForm.repeat_every
                                    }
                                })}
                                onChange={this.onChange}
                                style={{...webStyle.selectInput,width: '100px'}}
                                data-test-id="course_repeat_every_input"
                        >

                            {
                                this.state.dayNumberOptions.map(value => <MenuItem
                                    value={value} key={value}>
                                    <Typography style={webStyle.selectMenuItemText}>
                                        {value}
                                    </Typography>
                                </MenuItem>)
                            }
                        </Select> {repeatLabel}
                    </Box>

                    <Box style={webStyle.priceContainer}>
                        {this.renderOccursOn()}
                    </Box>
                    <Box sx={webStyle.priceContainer}>
                        <label className={`editCourseFormLabel`} style={{flex: '0 0 10em'}}>End Date</label>
                        By <Input name={"end_date"}
                                  type="date"
                                  placeholder="Enter End Date"
                                  value={this.state.courseForm.end_date}
                                  onBlur={this.onBlur}
                                  onChange={this.onChange}
                                  className={`editCourseFormInput`}
                                  style={webStyle.selectInput}
                                  data-test-id="course_end_date_input"/>
                    </Box>


                </>
            )
        }
    }

    renderError = (name: any) => {
        if ((this.state.errors || []).length > 0) {

            const errorMessage = this.state.errors.find(e => e[name])
            return (
                <>
                    <Typography variant='subtitle1' style={{
                        color: '#e90d0d',
                        fontWeight: 'normal',
                        fontSize: 14
                    }}>
                        {errorMessage && errorMessage[name]}
                    </Typography>
                </>

            )
        }
    }
    render() {
        let courseCrumbTitle;
        if (this.props.match.params.id) {
            courseCrumbTitle = 'Edit Course'
        } else {
            courseCrumbTitle = 'Create Course'
        }
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <ToastContainer/>
                {
                    this.state.loading ? (
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress size="2rem" color="primary"/>
                        </div>)
                        :
                        (
                            <Box sx={webStyle.container}>
                            <Box sx={webStyle.relativeContainer}>
                                <Box sx={webStyle.pathContainer}>
                                    <p style={webStyle.courseTitleLight}>Courses</p>
                                    <img src={RIGHTARROW} alt={"icon"} style={{width: '16px', height: '16px'}}/>
                                    <p style={webStyle.courseTitleDark}>{courseCrumbTitle}</p>
                                </Box>
                            </Box>
                            <Paper style={{padding: '16px'}}>
                                <Box style={webStyle.descContainer}>
                                    <Box sx={{...webStyle.inputGroup, flex: 1.5}}>
                                        <label
                                            className={`editCourseFormLabel required ${this.renderLabelClasses('name')}`}>Courses</label>
                                        <Input name={"name"}
                                               placeholder="Enter Course Name"
                                               value={this.state.courseForm.name}
                                               onBlur={this.onBlur}
                                               onChange={this.onChange}
                                               className={`editCourseFormInput ${this.renderErroredInputClasses('name')}`}
                                               data-test-id="course_name_input"/>
                                        {this.renderError('name')}
                                    </Box>
                                    <Box sx={{...webStyle.inputGroup, flex: 3}}>
                                        <label className={`editCourseFormLabel`}>Description</label>
                                        <Input name={"description"}
                                               placeholder="Enter Description"
                                               value={this.state.courseForm.description}
                                               onBlur={this.onBlur}
                                               onChange={this.onChange}
                                               className="editCourseFormInput"
                                               data-test-id="course_description_input"/>
                                    </Box>
                                </Box>
                                <Box sx={webStyle.fileInputAndCategoryContainer}>
                                    <Box sx={webStyle.fileInputContainer}>
                                        <input ref={this.fileInputRef}
                                               name={"course_image"}
                                               type="file"
                                               style={{display: "none"}}
                                               onChange={this.onChange}
                                               accept="image/png,image/jpeg"
                                               data-test-id="course_image_input"
                                        />
                                        <label className={"editCourseFormLabel"} htmlFor="file-select">Upload
                                            Image</label>
                                        <Box data-test-id="image_container" sx={webStyle.fileUploadBox} onClick={this.onCourseImageButtonClickHandler}>
                                            {
                                                this.renderCourseImage(this.imageUrl)
                                            }
                                        </Box>
                                    </Box>
                                    <Box sx={webStyle.categoryContainer}>
                                        <Box sx={webStyle.inputGroup}>
                                            <label className={`editCourseFormLabel required ${this.renderLabelClasses('category_id')}`}>Category</label>
                                            <Select variant="outlined"
                                                    name="category_id"
                                                    value={this.state.courseForm.category_id}
                                                    displayEmpty
                                                    renderValue={this.state.courseForm.category_id !== "" ? undefined : () => "Select Category"}
                                                    className={`${this.renderErroredInputClasses('category_id')}`}
                                                    onBlur={this.onBlur}
                                                    onClose={() => this.onBlur({
                                                        target: {
                                                            name: "category_id",
                                                            value: this.state.courseForm.category_id
                                                        }
                                                    })}
                                                    onChange={this.onChange}
                                                    style={{...webStyle.selectInput, width: '100%'}}
                                                    data-test-id="course_category_input"
                                            >
                                                {
                                                    this.state.categoryOptions.map(({value, name}) => <MenuItem
                                                        value={value} key={value}>
                                                        <Typography style={webStyle.selectMenuItemText}>
                                                            {name}
                                                        </Typography>
                                                    </MenuItem>)
                                                }
                                            </Select>
                                            {this.renderError('category')}
                                        </Box>
                                        <Box sx={webStyle.inputGroup}>
                                            <label
                                                className={`editCourseFormLabel required ${this.renderLabelClasses('course_sub_category_id')}`}>Sub
                                                Category</label>
                                            <Select variant="outlined"
                                                    name="course_sub_category_id"
                                                    placeholder={"UI UX Design"}
                                                    value={this.state.courseForm.course_sub_category_id}
                                                    displayEmpty
                                                    renderValue={this.state.courseForm.course_sub_category_id !== "" ? undefined : () => "Select Sub Category"}
                                                    className={`${this.renderErroredInputClasses('course_sub_category_id')}`}
                                                    onBlur={this.onBlur}
                                                    onClose={() => this.onBlur({
                                                        target: {
                                                            name: "course_sub_category_id",
                                                            value: this.state.courseForm.course_sub_category_id
                                                        }
                                                    })}
                                                    onChange={this.onChange}
                                                    style={{...webStyle.selectInput, width: '100%'}}
                                                    data-test-id="course_sub_category_input"
                                            >
                                                {
                                                    this.state.subCategoryOptions.map(({value, name}) => <MenuItem
                                                        value={value} key={value}>
                                                        <Typography style={webStyle.selectMenuItemText}>
                                                            {name}
                                                        </Typography>
                                                    </MenuItem>)
                                                }
                                            </Select>
                                            {this.renderError('course_sub_category')}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={webStyle.priceContainer}>
                                    <Box sx={webStyle.selectInputGroup}>
                                        <label
                                            className={`editCourseFormLabel`}>Course
                                            Type</label>
                                        <Select variant="outlined"
                                                name="choice"
                                                value={this.state.courseForm.choice}
                                                displayEmpty
                                                renderValue={this.state.courseForm.choice !== "" ? undefined : () => "Select"}
                                                className={`${this.renderErroredInputClasses('choice')}`}
                                                onBlur={this.onBlur}
                                                onClose={() => this.onBlur({
                                                    target: {
                                                        name: "choice",
                                                        value: this.state.courseForm.choice
                                                    }
                                                })}
                                                placeholder="Select"
                                                onChange={this.onChange}
                                                style={webStyle.selectInput}
                                                data-test-id="course_type_input"
                                        >
                                            {
                                                this.state.courseTypeOptions.map(({value, name}) => <MenuItem
                                                    value={value} key={value}>
                                                    <Typography style={webStyle.selectMenuItemText}>
                                                        {name}
                                                    </Typography>
                                                </MenuItem>)
                                            }
                                        </Select>
                                        {this.renderError('choice')}
                                    </Box>
                                    <Box sx={webStyle.selectInputGroup}>
                                        <label className={`editCourseFormLabel`}>Level</label>
                                        <Select variant="outlined"
                                                name="level"
                                                placeholder={"Select"}
                                                value={this.state.courseForm.level}
                                                displayEmpty
                                                renderValue={this.state.courseForm.level !== "" ? undefined : () => "Select"}
                                                className={`${this.renderErroredInputClasses('level')}`}
                                                onBlur={this.onBlur}
                                                onClose={() => this.onBlur({
                                                    target: {
                                                        name: "level",
                                                        value: this.state.courseForm.level
                                                    }
                                                })}
                                                onChange={this.onChange}
                                                style={webStyle.selectInput}
                                                data-test-id="course_level_input"
                                        >
                                            {
                                                this.state.courseLevelOptions.map(({value, name}) => <MenuItem
                                                    value={value} key={value}>
                                                    <Typography style={webStyle.selectMenuItemText}>
                                                        {name}
                                                    </Typography>
                                                </MenuItem>)
                                            }
                                        </Select>
                                        {this.renderError('level')}
                                    </Box>
                                    <Box sx={webStyle.selectInputGroup}>
                                        <label className={`editCourseFormLabel required ${this.renderLabelClasses('currency_id')}`}>Currency</label>
                                        <Select variant="outlined"
                                                name="currency_id"
                                                className={`${this.renderErroredInputClasses('currency_id')}`}
                                                value={this.state.courseForm.currency_id}
                                                displayEmpty
                                                renderValue={this.state.courseForm.currency_id !== "" ? undefined : () => "Select"}
                                                onBlur={this.onBlur}
                                                onClose={() => this.onBlur({
                                                    target: {
                                                        name: "currency_id",
                                                        value: this.state.courseForm.currency_id
                                                    }
                                                })}
                                                onChange={this.onChange}
                                                style={webStyle.selectInput}
                                                data-test-id="course_currency_input"
                                        >
                                            {
                                                this.state.currencyOptions.map(({value, name}) => <MenuItem
                                                    value={value} key={value}>
                                                    <Typography style={webStyle.selectMenuItemText}>
                                                        {name}
                                                    </Typography>
                                                </MenuItem>)
                                            }
                                        </Select>
                                        {this.renderError('currency')}
                                    </Box>
                                    <Box sx={webStyle.selectInputGroup}>
                                        <label className={`editCourseFormLabel required ${this.renderLabelClasses('price')}`}>Price</label>
                                        <Input name={"price"}
                                               placeholder={"Enter Price"}
                                               className={`editCourseFormInput ${this.renderErroredInputClasses('price')}`}
                                               value={this.state.courseForm.price}
                                               onBlur={this.onBlur}
                                               onChange={this.onChange}
                                               data-test-id="course_price_input"
                                        />
                                        {this.renderError('price')}
                                    </Box>
                                </Box>
                                <Box sx={webStyle.textContainer}>
                                    <label className={`editCourseFormLabel`}>Course overview</label>
                                    <ReactQuill value={this.state.courseForm.course_overview}
                                                modules={this.module}
                                                formats={this.format}
                                                placeholder={"Enter Course Overview"}
                                                style={webStyle.courseOverviewReactQuillBox}
                                                className={`${this.renderErroredInputClasses('course_overview')}`}
                                                onBlur={() => this.onBlur({
                                                    target: {
                                                        name: "course_overview",
                                                        value: this.state.courseForm.course_overview
                                                    }
                                                })}
                                                onChange={(value: any) => this.onChange({
                                                    target: {
                                                        name: "course_overview",
                                                        value
                                                    }
                                                })}
                                                data-test-id="course_overview_input"
                                    />
                                </Box>
                                <Box sx={webStyle.priceContainer}>
                                    <Box sx={webStyle.selectInputGroup}>
                                        <label className={`editCourseFormLabel`}>Date</label>
                                        <Box>
                                            <Input name={"start_date"}
                                                   type="date"
                                                   placeholder="Enter Start date"
                                                   value={this.state.courseForm.start_date}
                                                   onBlur={this.onBlur}
                                                   onChange={this.onChange}
                                                   className={`editCourseFormInput ${this.renderErroredInputClasses('start_date')}`}
                                                   style={{...webStyle.selectInput, marginRight: '10px'}}
                                                   data-test-id="course_start_date_input"/>
                                            <Input name={"end_date"}
                                                   type="date"
                                                   placeholder="Enter End Date"
                                                   disabled={this.state.courseForm.recurring_meeting}
                                                   value={this.state.courseForm.end_date}
                                                   onBlur={this.onBlur}
                                                   onChange={this.onChange}
                                                   className={`editCourseFormInput ${this.renderErroredInputClasses('end_date')}`}
                                                   style={webStyle.selectInput}
                                                   data-test-id="course_end_date_input"/>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box sx={{marginTop: '20px'}}>
                                    <Box sx={webStyle.selectInputGroup}>
                                        <Radio name={"recurring_meeting"}
                                                   checked={this.state.courseForm.recurring_meeting}
                                                   onChange={this.onChange}
                                                   className={`${this.renderErroredInputClasses('recurring_meeting')}`}
                                                   data-test-id="course_recurring_meeting_input">Recurring meeting</Radio>
                                        {
                                            this.renderRecurringMeetingElements(this.state.courseForm.recurring_meeting)
                                        }
                                    </Box>
                                </Box>
                                <Box sx={webStyle.skillContainer}>
                                    <label className={`editCourseFormLabel`}>Skills you'll learn</label>
                                    {
                                        this.state.courseForm.skill.map(({id, skill_name}, index) => (
                                            <Box sx={{...webStyle.inputGroup, flexDirection: "row"}} key={index}>
                                                <Input name={`skill-${index}`}
                                                       placeholder={"Add Skill"}
                                                       value={skill_name}
                                                       onBlur={this.onBlur}
                                                       onChange={this.onChange}
                                                       className={'editCourseFormInput'}
                                                       data-test-id="course_skill_input"
                                                />
                                                <ButtonBase data-test-id="delete_skill_btn"
                                                            onClick={() => this.onDeleteSkillButtonClickHandler(index, id)}>
                                                    <img alt={"delete"}
                                                         style={{color: 'red'}} src={DELETE}/>
                                                </ButtonBase>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                <ButtonBase data-test-id="addskill_id" style={webStyle.addMoreSkill}
                                            onClick={this.onAddSkillButtonClickHandler}
                                >
                                    <img alt={"add"} src={BLUEPLUS}/>
                                    Add Skill
                                </ButtonBase>
                            </Paper>

                                <Box sx={webStyle.bottomContainer}>
                                    <ButtonBase
                                        data-test-id="cancel_btn"
                                        onClick={this.onCancelButtonClickHandler}
                                        className="btn"
                                    >
                                        <Box sx={webStyle.outlineBtn}>Cancel</Box>
                                    </ButtonBase>
                                    <ButtonBase
                                        onClick={this.onSaveButtonClickHandler}
                                        data-test-id="save_test_details_btn"
                                    >
                                        <Box sx={webStyle.containedBtn}>Save</Box>
                                    </ButtonBase>
                                </Box>
                            </Box>
                        )}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
    }

    // Customizable Area End
}

// Customizable Area Start
const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        flex: "1",
        marginBottom: "20px",
        marginLeft: "20px",
        marginTop: "24px"
    },
    relativeContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "0px!important"
    },
    pathContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        padding: "8px 0"
    },
    courseTitleLight: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        color: "#9D9D9D",
        margin: 0
    },
    courseTitleDark: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        color: "#000000",
        margin: 0
    },
    descContainer: {
        display: "flex",
        gap: "20px"
    },
    fileInputAndCategoryContainer: {
        display: "flex",
        gap: "16px",
        flexWrap: "wrap",
        marginTop: "16px"
    },
    fileInputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        flex: 1.5
    },
    fileUploadBox: {
        width: "150px",
        height: "150px",
        border: "1px solid #FEE7D7",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
        padding: "8px"
    },
    fileUploadBoxText: {
        fontSize: "14px",
        fontWeight: 600
    },
    selectMenuItemText: {
        fontSize: "14px"
    },
    categoryContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 3
    },
    inputGroup: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        flex: 1.5
    },
    inputStyle: {
        height: "40px",
        outline: "none",
        padding: "8px",
        borderRadius: "8px",
        border: "1px solid #FEE7D7"
    },
    text16: {
        fontSize: "16px"
    },
    fileContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        marginTop: "20px"
    },
    fileBox: {
        display: "flex",
        alignItems: "center",
        gap: "20px"
    },
    uploadFileBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as "column"
    },
    fileBoxSelector: {
        width: "150px",
        height: "150px",
        border: "1px solid #E7E7FE",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center"
    },
    fileRemoveBtn: {
        position: "absolute",
        top: "10px",
        right: "10px"
    },
    priceContainer: {
        display: "flex",
        gap: "10px",
        marginTop: "20px"
    },
    selectInputGroup: {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "5px",
        maxWidth: "fit-content"
    },
    selectInput: {
        width: "200px",
        height: "42px",
        border: "1px solid #FEE7D7",
        borderRadius: "8px",
        boxShadow: "none !important",
        fontSize: "14px"
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "10px",
        marginTop: "20px",
        height: "200px",
        gap: "5px"
    },
    courseOverviewReactQuillBox: {
        flex: "1",
        marginBottom: "42px"
    },
    skillContainer: {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "10px;",
        marginTop: "20px",
        width: "300px"
    },
    activeSkill: {
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "center",
        gap: "10px",
        width: "225px"
    },
    addMoreSkill: {
        marginTop: "20px",
        color: "#3B3CEA"
    },
    outlineBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#3B3CEA",
        backgroundColor: "white",
        border: "1px solid #3B3CEA",
        padding: "12px 50px",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 600
    },
    bottomContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "20px",
        alignItems: "center",
        paddingRight: "20px"
    },
    containedBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffff",
        backgroundColor: "#3B3CEA",
        border: "1px solid #ffffff",
        padding: "13px 50px",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 600
    }
};
// Customizable Area End
