// App.js - WEB
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import './App.css';
import 'antd/dist/antd.css';
import { notification } from 'antd';

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

class App extends Component {

  render() {
    return (
      <>
        <WebRoutes />
      </>
    );
  }
}

export default App;
