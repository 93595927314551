//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter, } from 'react-router-dom';
//* Private Routes List start *//
import Loader from '../../components/src/Loader.web';
import PrivateRoute from './PrivateRoute.web';
import EditCourseWeb from '../../blocks/email-account-registration/src/EditCourse.web';

// * Routes List start *//
const SplashscreenWeb = lazy(() =>
  import('../../blocks/splashscreen/src/SplashscreenWeb.web')
);
const AdminSideBar = lazy(() =>
  import('../../blocks/adminconsole2/src/adminpannel/AdminSideBar.web')
);
const AdminDashboard = lazy(() =>
  import('../../blocks/adminconsole2/src/adminpannel/AdminDashboard.web')
);
const ErrorPage = lazy(() =>
  import('../../blocks/adminconsole2/src/errorpage/ErrorPage.web')
);
const InstructorDashboard = lazy(() =>
  import('../../blocks/adminconsole2/src/InstructorDashboard.web')
);
const InstructorStudents = lazy(() =>
  import('../../blocks/assessmenttest/src/InstructorStudents.web')
);
const InstructorAssementStudentAnswers = lazy(() =>
  import('../../blocks/assessmenttest/src/InstructorAssementStudentAnswers.web')
);
const InstructorCalendar = lazy(() =>
  import('../../blocks/email-account-registration/src/InstructorCalendar.web')
);
const CourseHome = lazy(() =>
  import('../../blocks/email-account-registration/src/CourseHome.web')
);
const StudentViewCourse = lazy(() =>
  import('../../blocks/email-account-registration/src/StudentViewCourse.web')
);
const ViewCourse = lazy(() =>
  import('../../blocks/email-account-registration/src/ViewCourse.web')
);
const EmailAccountLoginWeb = lazy(() =>
  import('../../blocks/email-account-login/src/EmailAccountLogin.web')
);
const EmailAccountRegistrationWeb = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailAccountRegistration.web')
);
const OTPInputAuthWeb = lazy(() =>
  import('../../blocks/otp-input-confirmation/src/OTPInputAuth.web')
);
const ForgotPasswordWeb = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPassword.web')
);
const ForgotPasswordOTPWeb = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPasswordOTP.web')
);
const NewPasswordWeb = lazy(() =>
  import('../../blocks/forgot-password/src/NewPassword.web')
);
const RecruiterDashboard = lazy(() =>
  import('../../blocks/adminconsole2/src/RecruiterDashboard.web')
);
const Terms = lazy(() =>
  import('../../blocks/adminconsole2/src/Terms.web')
);
const Faqs = lazy(() =>
  import('../../blocks/adminconsole2/src/Faqs.web')
);
const ViewAllJobs = lazy(() =>
  import('../../blocks/requestmanagement/src/ViewAllJobs.web')
);

const NotificationCenter = lazy(() =>
  import('../../blocks/email-account-registration/src/NotificationCenter.web')
);
const NotificationCenterSettings = lazy(() =>
  import('../../blocks/email-account-registration/src/NotificationCenterSettings.web')
);
const UserProfile = lazy(() =>
  import('../../blocks/email-account-registration/src/UserProfile.web')
);
const EditUserProfile = lazy(() =>
  import('../../blocks/email-account-registration/src/EditUserProfile.web')
);
const Roleasandpermissions = lazy(() =>
  import('../../blocks/rolesandpermissions/src/Rolesandpermissions.web')
);
const EditPermissions = lazy(() =>
  import('../../blocks/rolesandpermissions/src/EditPermissions.web')
);
const Help = lazy(() => import('../../blocks/requestmanagement/src/Help.web'));
const Privacy = lazy(() =>
  import('../../blocks/requestmanagement/src/Privacy.web')
);
const JobList = lazy(() =>
  import('../../blocks/email-account-registration/src/JobList')
);
const Job = lazy(() =>
  import('../../blocks/email-account-registration/src/Job')
);
const Applications = lazy(() =>
  import('../../blocks/catalogue/src/Applications.web')
);

const ResumeProfile = lazy(() =>
  import('../../blocks/catalogue/src/ResumeProfile.web')
);

const StudentInstructorUserProfile = lazy(() =>
  import('../../blocks/email-account-registration/src/StudentInstructorUserProfile.web')
);
const StudentDashboard = lazy(() =>
  import('../../blocks/adminconsole2/src/StudentDashboard.web')
);
const StudentRunningCourses = lazy(() =>
  import('../../blocks/adminconsole2/src/StudentRunningCourses.web')
);


const Joblisting = lazy(() =>
  import('../../blocks/joblisting/src/Joblisting.web')
);

const ViewAllJobsStudent = lazy(() =>
  import('../../blocks/joblisting/src/ViewAllJobs.web')
);

const JobDetails = lazy(() =>
  import('../../blocks/joblisting/src/JobDetails.web')
);

const JobApply = lazy(() =>
  import('../../blocks/joblisting/src/JobApply.web')
);
//* Private Routes List end *//


function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <SplashscreenWeb {...props} />}
          />
          <Route
            path="/error"
            exact
            render={props => <ErrorPage {...props} />}
          />

          <Route
            path="/Login"
            render={props => <EmailAccountLoginWeb {...props} />}
          />

          <Route
            path="/Signup"
            render={props => <EmailAccountRegistrationWeb {...props} />}
          />

          <Route
            path="/OTPInputAuthWeb"
            render={props => <OTPInputAuthWeb {...props} />}
          />

          <Route
            path="/ForgotPasswordWeb"
            render={props => <ForgotPasswordWeb {...props} />}
          />

          <Route
            path="/ForgotPasswordOTPWeb"
            render={props => <ForgotPasswordOTPWeb {...props} />}
          />

          <Route
            path="/NewPasswordWeb"
            render={props => <NewPasswordWeb {...props} />}
          />

          <PrivateRoute path="/admin">
            <AdminSideBar>
              <Route
                path="/admin/dashboard"
                exact
                render={props => <StudentDashboard {...props} />}
              />
              <Route
                path="/admin/dashboard/running-courses"
                exact
                render={props => <StudentRunningCourses {...props} />}
              />

              <Route
                path="/admin/course"
                exact
                render={props => <CourseHome {...props} />}
              />
              <Route
                path="/admin/courses/:categoryId"
                exact
                render={props => <CourseHome {...props} />}
              />
              <Route
                path="/admin/courses/viewcourse/:id"
                exact
                render={props => <StudentViewCourse {...props} />}
              />
              <Route path="/admin/students" />
              <Route path="/admin/instructor" />
              <Route path="/admin/recuriter" />
              <Route
                path="/admin/jobs"
                exact
                render={props => <Joblisting {...props} />}
              />
              <Route
                path="/admin/jobs/all"
                exact
                render={props => <ViewAllJobsStudent {...props} />}
              />
              <Route
                path="/admin/jobs/job-details/:id"
                exact
                render={props => <JobDetails {...props} />}
              />
              <Route
                path="/admin/jobs/job-apply/:id"
                exact
                render={props => <JobApply {...props} />}
              />
              <Route
                path="/admin/roles"
                exact
                render={props => <Roleasandpermissions {...props} />}
              />
              <Route
                path="/admin/adduser"
                exact
                render={props => <EditPermissions {...props} />}
              />
              <Route
                path="/admin/help"
                exact
                render={props => <Help {...props} />}
              />
              <Route path="/admin/accounts" />
              <Route
                path="/admin/privacypolicy"
                exact
                render={props => <Privacy {...props} />}
              />
              <Route
                path="/admin/userprofile"
                render={props => <StudentInstructorUserProfile {...props} />}
              />
              <Route path="/admin/calendar" />
              <Route
                path="/admin/terms-conditions"
                exact
                render={props => <Terms {...props} />}
              />
              <Route
                path="/admin/privacy-policy"
                exact
                render={props => <Privacy {...props} />}
              />
              <Route
                path="/admin/faqs"
                exact
                render={props => <Faqs {...props} />}
              />
            </AdminSideBar>
          </PrivateRoute>

          <PrivateRoute path="/instructor">
            <AdminSideBar>
              <Route
                path="/instructor/dashboard"
                exact
                render={props => <InstructorDashboard {...props} />}
              />
              <Route
                path="/instructor/course"
                exact
                render={props => <CourseHome {...props} />}
              />
              <Route
                path="/instructor/courses/createcourse"
                exact
                render={props => <EditCourseWeb {...props} />}
              />
              <Route
                path="/instructor/courses/edit/:id"
                exact
                render={props => <EditCourseWeb {...props} />}
              />
              <Route
                path="/instructor/courses/viewcourse/:id"
                exact
                render={props => <ViewCourse {...props} />}
              />
              <Route
                path="/instructor/assessment"
                exact
                render={props => <InstructorStudents {...props} />}
              />
              <Route
                path="/instructor/calendar"
                exact
                render={props => <InstructorCalendar {...props} />}
              />
              <Route
                path="/instructor/assessment/answers"
                exact
                render={props => (
                  <InstructorAssementStudentAnswers {...props} />
                )}
              />
              <Route
                path="/instructor/notificationcenter"
                render={props => <NotificationCenter {...props} />}
              />
              <Route
                path="/instructor/notificationcentersettings"
                render={props => <NotificationCenterSettings {...props} />}
              />
              <Route
                path="/instructor/edituserprofile"
                render={props => <EditUserProfile {...props} />}
              />
              <Route
                path="/instructor/privacypolicy"
                exact
                render={props => <Privacy {...props} />}
              />
              <Route
                path="/instructor/help"
                exact
                render={props => <Help {...props} />}
              />
              <Route
                path="/instructor/userprofile"
                render={props => <StudentInstructorUserProfile {...props} />}
              />
              <Route
                path="/instructor/terms-conditions"
                exact
                render={props => <Terms {...props} />}
              />
              <Route
                path="/instructor/privacy-policy"
                exact
                render={props => <Privacy {...props} />}
              />
              <Route
                path="/instructor/faqs"
                exact
                render={props => <Faqs {...props} />}
              />
            </AdminSideBar>
          </PrivateRoute>
          <PrivateRoute path="/recruiter">
            <AdminSideBar>
              <Route
                path="/recruiter/dashboard"
                exact
                render={props => <RecruiterDashboard {...props} />}
              />

              <Route
                path="/recruiter/notificationcenter"
                render={props => <NotificationCenter {...props} />}
              />
              <Route
                path="/recruiter/notificationcentersettings"
                render={props => <NotificationCenterSettings {...props} />}
              />
              <Route
                path="/recruiter/userprofile"
                render={props => <UserProfile {...props} />}
              />
              <Route
                path="/recruiter/edituserprofile"
                render={props => <EditUserProfile {...props} />}
              />
              <Route
                path="/recruiter/privacypolicy"
                exact
                render={props => <Privacy {...props} />}
              />
              <Route
                path="/recruiter/help"
                exact
                render={props => <Help {...props} />}
              />

              <Route
                path="/recruiter/jobList"
                exact
                render={props => (
                  <Redirect
                    to={{
                      pathname: '/recruiter/jobList/active',
                      state: { name: 'active' },
                      params: { type: 'active' }
                    }}
                  />
                )}
              />
              <Route
                path="/recruiter/applications"
                exact
                render={props => <Applications {...props} />}
              />
              <Route
                path="/recruiter/profile/:id"
                exact
                render={props => <ResumeProfile {...props} />}
              />
              <Route
                path="/recruiter/jobList/:type"
                exact
                render={props => <JobList {...props} />}
              />
              <Route
                path="/recruiter/job/:id/edit"
                exact
                render={props => <Job {...props} />}
              />

              <Route
                path="/recruiter/job/create"
                exact
                render={props => <Job {...props} />}
              />
              <Route
                path="/recruiter/terms-conditions"
                exact
                render={props => <Terms {...props} />}
              />
              <Route
                path="/recruiter/privacy-policy"
                exact
                render={props => <Privacy {...props} />}
              />
              <Route
                path="/recruiter/faqs"
                exact
                render={props => <Faqs {...props} />}
              />
            </AdminSideBar>
          </PrivateRoute>
          <Route path="*" render={props => <Redirect to="/Login" />} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default withRouter(WebRoutes);
